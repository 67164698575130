<template>
  <div class="base-box">
    <UploadInfoCom
      @uploadSuccess="uploadSuccessCallBack" :options="options" />
    <SearchInput
      :placeHolder="placeHolder"
      @searchInput="searchInput" />
    <SwitchTable 
      @changeAsyncType="changeAsyncType"
      :asyncInfo="asyncInfo" />
    <TableList
      v-if="showCom"
      :dataList="dataList"
      :total="total"
      :listType="listType"
      :pageNum="pageNum"
      :pageSize="pageSize"
      @operate="operate"
      @currentPageChange="currentPageChange"
      @pageSizeChange="pageSizeChange" />
      <el-dialog
        class="unionOrder-dialog"
        title="关联合同"
        :visible.sync="dialogVisible"
      >
        <ul class="dialog-content">
          <li>
            <span></span>
            <span>主合同名称</span>
            <span>主合同主键ID</span>
          </li>
          <li v-for="(item, index) in billsContractList" :key="index">
            <span>
              <input type="radio" name="billsRadio" :value="item.id" v-model="checkedValue">
            </span>
            <span>{{item.contractName}}</span>
            <span>{{item.sourceId}}</span>
          </li>
        </ul>
        <footer class="dialog-footer-btn">
          <span @click="dialogVisible = false">取消</span>
          <span @click="relevantContract">确定</span>
        </footer>
      </el-dialog>
  </div>
</template>

<script>
import UploadInfoCom from '@/components/UploadInfoCom/Index.vue'
import TableList from '@/components/UploadInfoCom/TableList.vue'
import SearchInput from '@/components/SearchInput/Index.vue'
import SwitchTable from '@/components/UploadInfoCom/SwitchTable.vue'
export default {
  name: "unionOrder",
  data() {
    return{
      dialogVisible: false,
      billsContractList: [],
      checkedValue: '',
      dataList: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      placeHolder: '请输入合同名称、经办人、签约主体关键词进行搜索',
      companyName: '',
      listType: '0',
      showCom: true,
      asyncInfo: {},
      options: [{
        label: '老工联单',
        value: 'BILLS'
      },{
        label: '新工联单',
        value: 'NEW_BILLS'
      },{
        label: '投管工联单',
        value: 'SHTZ_BILLS'
      }],
      relevanceData: {}
    }
  },
  components: {
    UploadInfoCom,
    TableList,
    SearchInput,
    SwitchTable
  },
  methods: {
    init() {
      this.pageNum = 1
      this.getQueryBillsContractList()
      // this.getRelevantBillsContract()
    },
    // 获取合同列表
    getRelevantBillsContract() {
      this.$request.get(this.URL.relevantBillsContract + '?type=' + this.relevanceData.sourceSystem).then(res => {
        if (res.code === '200') {
          this.billsContractList = res.data
        }
      })
    },
    // 确定选择关联的合同
    relevantContract() {
      if (!this.checkedValue) return
      var _arr = this.billsContractList.filter(item => item.id == this.checkedValue)[0]
      var obj = {
        params: {
          contractId: _arr.sourceId,
          agreeId: this.relevanceData.id,
          sourceSystem: this.relevanceData.sourceSystem
        }
      }
      this.$request.post(this.URL.relevantContract, obj).then(res => {
        if (res.code === '200') {
          this.$message.success(res.data.result)
          this.pageNum = 1
          this.getQueryBillsContractList()
          this.dialogVisible = false
        }
      })
    },
    // 取消关联
    unRrelevantContract() {
      var obj = {
        params: {
          agreeId: this.relevanceData.id,
          sourceSystem: this.relevanceData.sourceSystem
        }
      }
      this.$request.post(this.URL.unRrelevantContract, obj).then(res => {
        if (res.code === '200') {
          this.pageNum = 1
          this.getQueryBillsContractList()
          this.$message.success(res.message)
        }
      })
    },
    uploadSuccessCallBack() {
      this.init()
    },
    // 切换列表
    changeAsyncType(val) {
      this.showCom = false
      this.listType = val === 'Y' ? '1' : '0'
      this.init()
    },
    // 列表操作
    operate(type, obj) {
      this.relevanceData = obj
      if (type === 'delete') {
        this.deleteBillsContract(obj.id)
      } else if (type === 'relevance') {
        // 关联合同
        this.dialogVisible = true
        this.getRelevantBillsContract()
      } else if (type === 'cancel') {
        // 取消同步
        this.unSyncBills()
      } else if (type === 'cancelRelevance') {
        // 取消关联
        this.unRrelevantContract()
      }
    },
    // 取消同步
    unSyncBills() {
      var obj = {
        params: this.relevanceData.id
      }
      this.$request.post(this.URL.unSyncBills, obj).then(res => {
        this.init()
      })
    },
    deleteBillsContract(id) {
      const obj = {
        params: {
          id: id
        }
      }
      this.$request.post(this.URL.deleteBillsContract, obj).then(res => {
        if (res.code === '200') {
          this.$message.success('删除成功')
          this.init()
        }
      })
    },
    // 分页-切换页码
    currentPageChange(val) {
      this.pageNum = val
      this.getQueryBillsContractList()
    },
    // 分页-却换pageSize
    pageSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    // 搜索
    searchInput(val) {
      this.companyName = val
      this.init()
    },
    // 获取列表
    getQueryBillsContractList() {
      // 1-已同步 0-未同步
      var obj = {
        params: {
          name: this.companyName,
          type: this.listType,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
      this.$request.post(this.URL.queryBillsContractList, obj).then(res => {
        this.showCom = true
        if (res.code === '200') {
          // res.data.contractInfo.list.forEach(item => {
          //   item.contractDate = item.contractDate.split('T')[0]
          // })
          this.total = Number(res.data.contractInfo.total)
          var obj = {unsynchronized: '0', synchronization: '0'}
          if (this.listType === '1') {
            // 同步
            obj.synchronization = this.total
            obj.unsynchronized = res.data.count - this.total
          } else {
            // 未同步
            obj.unsynchronized = this.total
            obj.synchronization = res.data.count - this.total
          }
          this.asyncInfo = obj
          this.dataList = res.data.contractInfo.list
        }
      })
    }
  },
  mounted() {
    this.init()
  }
};
</script>
<style lang="less" scoped>
::v-deep .unionOrder-dialog .el-dialog__header{
  border-bottom: 1px solid #EAEAEA;
  .el-dialog__title{
    font-size: 14px;
    color: #323232;
    font-weight: 400;
  }
}
::v-deep .unionOrder-dialog .el-dialog__body{
  padding: 18px 0;
  box-sizing: border-box;
}
.dialog-content{
  width: 100%;
  height: 370px;
  overflow: auto;
  padding: 0 18px;
  box-sizing: border-box;
  li{
    display: flex;
    height: 36px;
    line-height: 36px;
    overflow: hidden;
    border: 1px solid #EAEAEA;
    &:first-child{
      background: #F7F8FC;
    }
    &:nth-child(n+2) {
      margin-top: -1px;
    }
    span{
      font-size: 12px;
      color: #323232;
      display: inline-block;
      &:first-child{
        display: inline-block;
        width: 36px;
        height: 100%;
        text-align: center;
      }
      &:nth-child(n+2) {
        flex: 1;
        padding: 0 12px;
        box-sizing: border-box;
        border-left: 1px solid #eaeaea;
      }
      input[type="radio"]{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        appearance: none;
        border: 1px solid #EAEAEA;
        box-sizing: border-box;
        outline: none;
        position: relative;
        overflow: hidden;
        vertical-align: middle;
      }
      input[type="radio"]:checked{
        border: none;
        &::before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url(../../assets/img/icon-checked.png) no-repeat center center;
          background-size: 100%
        }
      }
    }
  }
}
.dialog-footer-btn{
  width: 100%;
  margin-top: 18px;
  text-align: right;
  padding: 9px 18px;
  padding-bottom: 0px;
  box-sizing: border-box;
  border-top: 1px solid #EAEAEA;
  span{
    cursor: pointer;
    display: inline-block;
    width: 72px;
    height: 30px;
    line-height: 30px;
    background: #fff;
    text-align: center;
    font-size: 12px;
    color: #997236;
    border: 1px solid #F0D8A8;
    box-sizing: border-box;
    border-radius: 2px;
    &:last-child{
      background: #F0D8A8;
      color: #323232;
      margin-left: 10px;
    }
  }
}
</style>